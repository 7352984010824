.app-loader {
  border: 0.5rem solid transparent;
  border-radius: 50%;
  margin: auto;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
      var(--palette-green-dark),
      var(--palette-green-dark)
    ),
    linear-gradient(
      to right,
      var(--brand-light-blue),
      var(--palette-green-light)
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.app-loader-section > *:not(.app-loader-wrapper),
.app-loader-section > *:not(.app-loader-wrapper) * {
  opacity: 0.7;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
}
